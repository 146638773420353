<template>
  <div class="layoutDefault" style="margin-top: 2rem">
    <div class="grid grid-cols-1 gap-4">
      <div class="z-10">
        <div
          class="flex cursor-pointer rounded-lg py-2"
          style="max-width: 200px"
          @click="$router.go(-1)"
        >
          <IconArrow
            class="mr-3 mt-1 stroke-current text-habu h-6 w-6 stroke-2 transform rotate-0"
          />
          <span class="text-2xl text-normal">Notifications</span>
        </div>
      </div>

      <div class="hcard relative z-10 p-8">
        <div class="grid grid-cols-1 gap-6 mt-2 animate-pulse" v-if="loading">
          <div class="relative z-10" v-for="n in 3" :key="n">
            <div class="hcard-b17 flex xs:flex-col sm:flex-row p-5 h-full">
              <div class="flex-grow">
                <div class="grid-cols-1">
                  <div class="h-6 w-32 bg-hline rounded-lg"></div>

                  <div class="mt-1">
                    <div class="h-7 w-56 bg-hline rounded-lg"></div>
                  </div>
                  <div class="mt-1">
                    <div class="flex space-x-5">
                      <div class="flex">
                        <div class="h-5 w-28 bg-hline rounded-lg"></div>
                      </div>
                      <div class="flex">
                        <div class="h-5 w-20 bg-hline rounded-lg"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="place-self-center">
                  <div class="h-8 w-18 bg-hline rounded-lg"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 gap-6 mt-2" v-else>
          <div class="col-span-1 py-24" v-if="!listNotification.data.length">
            <img :src="NoData" class="mx-auto" />
            <div class="capitalize font-semibold text-5xl text-hblue text-center">
              no notification yet!
            </div>
          </div>

          <div class="relative z-10" v-for="ln in listNotification.data" :key="ln.id">
            <div class="hcard-b17 flex xs:flex-col sm:flex-row p-5 h-full">
              <div class="flex-grow" v-if="ln.ticket && ln.ticket.data">
                <div class="grid-cols-1">
                  <div class="font-semibold text-htext text-lg">
                    {{ ln.ticket.data.ticketType ? ln.ticket.data.ticketType.data.alias : '-' }}
                  </div>
                  <div class="mt-1">
                    <p
                      class="px-2 py-0.5 bg-hblue w-36 rounded-lg cursor-pointer"
                      @click="toTicket(ln.ticket)"
                    >
                      <span class="text-white text-sm">
                        {{ ln.ticket.data.number }}
                      </span>
                    </p>
                  </div>
                  <div class="mt-1">
                    <div class="flex space-x-5">
                      <div class="flex">
                        <IconProfile class="mr-2 h-4.16 w-4.16 mt-1" />
                        {{ ln.ticket.data.assigneeName }}
                      </div>
                      <div class="flex">
                        <IconTime class="mr-2 h-4.16 w-4.16 mt-1" />
                        {{ ln.created_at.date | moment('h:mm a') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="place-self-center">
                  <img v-if="ln.status == 'new'" :src="StatusNew" />
                  <img v-else-if="ln.status == 'confirmed'" :src="StatusConfirmed" />
                  <img v-else-if="ln.status == 'started'" :src="StatusStarted" />
                  <img v-else-if="ln.status == 'held'" :src="StatusOnHold" />
                  <img v-else-if="ln.status == 'restarted'" :src="StatusRestarted" />
                  <img v-else :src="StatusDone" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrow from '@/assets/icons/icon-arrow.svg?inline'
import IconTime from '@/assets/icons/icon-timeColored.svg?inline'
import IconProfile from '@/assets/icons/icon-profile.svg?inline'
import NoData from '@/assets/images/icon-noMessage.png'

import StatusNew from '@/assets/images/icon-new.png'
import StatusConfirmed from '@/assets/images/icon-confirmed.png'
import StatusStarted from '@/assets/images/icon-started.png'
import StatusOnHold from '@/assets/images/icon-onhold.png'
import StatusRestarted from '@/assets/images/icon-restarted.png'
import StatusDone from '@/assets/images/icon-done.png'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'notifications',
  components: {
    IconArrow,
    IconProfile,
    IconTime
  },
  data() {
    return {
      NoData,
      StatusNew,
      StatusConfirmed,
      StatusStarted,
      StatusOnHold,
      StatusRestarted,
      StatusDone,
      loading: true
    }
  },
  computed: {
    ...mapGetters('notif', ['listNotification'])
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapActions('notif', ['GET_NOTIFICATIONS', 'READ_NOTIFICATION']),
    toTicket(val) {
      if (val && val.data) {
        let removeSpacing = val.data.number ? val.data.number.replaceAll(/\s+/g, '-') : ''
        let removeSlash = removeSpacing.replaceAll('/', '')
        this.$router.push({ name: 'ticket.detail', params: { slug: removeSlash, id: val.data.id } })
      }
    },
    async getData() {
      this.loading = true
      await this.GET_NOTIFICATIONS()

      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }
}
</script>
